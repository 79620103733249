import React, { useState } from 'react'

const Component = (props) => {
  const { initialValue, onChange, type } = props
  const { id, databaseId, name } = props.item
  const [value, setValue] = useState(initialValue)
  const handleOnChange = () => {
    const toggleValue = !value
    setValue(toggleValue)
    onChange({ databaseId, type })
  }
  return (
    <div className="col-12 col-sm-6 col-md-4">
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          checked={value}
          id={id}
          onChange={handleOnChange}
        />
        <label className="form-check-label" htmlFor={id}>
          {name}
        </label>
      </div>
    </div>
  )
}

export default React.memo(Component)