import React, { useState, useEffect } from 'react'
import EventFilter from '../events/eventFilter'
import EventList from '../events/eventList'

const Component = ({ item, events }) => {
  // Sets the default activeDate to today
  const [activeDate, setActiveDate] = useState(
    new Date().toLocaleDateString('sv-SE').replace(/\D/g, '')
  )

  // Gets the taxonomies from the WordPress block settings, so you can decide to only show events from a specific taxonomy on a page
  const initialTaxonomies = {
    eventcategories: item.category?.map((obj) => obj.databaseId) || [],
    locations: item.locations?.map((obj) => obj.databaseId) || [],
    subscriptions: item.subscription?.map((obj) => obj.databaseId) || [],
  }
  const [taxonomies, setTaxonomies] = useState(initialTaxonomies)
  const [query, setQuery] = useState({ ...initialTaxonomies, s: '' })
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(false)
  const [initial, setInitial] = useState(false)
  const reverse = item?.options?.includes('flip')

  const filterEvents = (props) => {
    const { dateOnly } = props

    // Sorts events by the first date so that multi-show events gets pushed to the top
    const sortStartDate = (a, b) => {
      const firstDatetime = (a) => {
        const array = a.eventData.dateFull.filter((date) => date >= activeDate)
        const date = (
          array.length > 0 ? array[0] : a.eventData.dateStart
        ).toString()
        const time =
          'undefined' !== typeof a.eventData.info?.time &&
          a.eventData.info?.time
            ? a.eventData.info?.time
            : '00:00'
        const datetime = new Date(
          date.substring(0, 4),
          date.substring(4, 6),
          date.substring(6),
          time.substring(0, 2),
          time.substring(3),
          0
        )
        return datetime
      }
      // The WordPress block allows for a reverse display order
      if (!reverse) return firstDatetime(a.event) - firstDatetime(b.event)
      return firstDatetime(b.event) - firstDatetime(a.event)
    }

    return events
      .filter((event) => {
        // Filters events by doing the easiest calculations first and the more cpu heavy ones last

        // Removes all events has had all their shows before activeDate which by default is the current date
        // if the user selects a future date it filters out previous ones
        // The graphQL query also filters out old events during every build
        // but if the site hasn't been rebuilt for longer periods this also filters them out
        if (event.event.eventData.dateEnd < activeDate) return false

        // If the only filtereing is based on date exit early
        if (true === dateOnly) return true
        // Checks if the text search returns 0 events
        if (data && !data.includes(event.event.databaseId)) return false

        // If the search api from wordpress hasn't been used, filter events by taxonomy
        if (!data) {
          let keepElement = true

          if (taxonomies.eventcategories?.length > 0) {
            if (event.event.eventData?.categories) {
              const check = event.event.eventData.categories.map((obj) =>
                taxonomies.eventcategories.includes(obj.termTaxonomyId)
              )
              if (!check.includes(true)) {
                keepElement = false
              }
            } else {
              keepElement = false
            }
          }

          if (taxonomies.locations?.length > 0) {
            if (event.event.eventData?.location) {
              if (
                !taxonomies.locations.includes(
                  event.event.eventData.location.termTaxonomyId
                )
              ) {
                keepElement = false
              }
            } else {
              keepElement = false
            }
          }

          if (taxonomies.subscriptions?.length > 0) {
            if (event.event.eventData?.subscription) {
              const check = event.event.eventData.subscription.map((obj) =>
                taxonomies.subscriptions.includes(obj.termTaxonomyId)
              )
              if (!check.includes(true)) {
                keepElement = false
              }
            } else {
              keepElement = false
            }
          }

          return keepElement
        }
        return true
      })
      .sort(sortStartDate)
  }

  const [filteredEvents, setFilteredEvents] = useState(false)

  // Turns the taxonomies and search query into args for the rest api
  const objToQueryString = (obj) => {
    const keyValuePairs = []
    for (const key in obj) {
      keyValuePairs.push(
        encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])
      )
    }
    return keyValuePairs.join('&')
  }

  const updateEvents = (props) => {
    const { dateOnly } = props?.dateOnly || false
    setFilteredEvents(filterEvents({ dateOnly }))
  }

  // Sends api request if the text query string is changed
  useEffect(() => {
    // Skips sending requests on init
    if (!initial) {
      setInitial(true)
      return
    }
    setLoading(true)
    const urlRoot =
      process.env.WPAPI_URL || 'https://admin.orebrokonserthus.com/wp-json/'
    fetch(`${urlRoot}core/v1/events/?${objToQueryString(query)}`)
      .then((response) => response.json())
      .then((resultData) => {
        setData(resultData.results)
        setLoading(false)
      })
  }, [query])

  useEffect(() => {
    updateEvents({ dateOnly: true })
  }, [activeDate])

  useEffect(() => {
    if (!initial) return
    updateEvents()
  }, [data])

  return (
    <section className={`events`}>
      {item?.options?.includes('hide') && item.title && (
        <section className="content mb-0 mb-sm-5 text-center">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 title">
                    <h2 className="subtitle">{item.title}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {!item?.options?.includes('hide') && (
        // if the WordPress block settings only wants to display events this hides the filtering
        <EventFilter
          title={item.title}
          options={item?.options}
          changeActiveDate={(date) =>
            setActiveDate(date?.toLocaleDateString('sv-SE').replace(/\D/g, ''))
          }
          changeTaxonomy={(tax) => setTaxonomies(tax)}
          initialTaxonomies={initialTaxonomies}
          onSearchClick={(searchQuery) => {
            setQuery({ ...taxonomies, s: searchQuery })
          }}
        />
      )}
      <EventList
        filteredEvents={filteredEvents}
        loading={loading}
        activeDate={activeDate}
      />
    </section>
  )
}

export default Component
