import React from "react"
const Component = ({ item, num, pageContext }) => {
  return (
    <section className={`quote`}>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 text-center">
            <figure>
              <blockquote>{item.quote}</blockquote>
              <figcaption>{item.text}</figcaption>
            </figure>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Component
