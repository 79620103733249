import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import parse from "html-react-parser"

import Moment from "react-moment"
import "moment/locale/sv"

const Component = ({ item, num, pageContext }) => {
  const { language } = pageContext
  const strings = {
    en: {
      selectYear: "Select year",
      showMore: 'Show more'
    },
    sv: {
      selectYear: "Välj år",
      showMore: 'Visa fler'
    },
  }
  const { allWpTour } = useStaticQuery(
    graphql`
      query {
        allWpTour(
          sort: { fields: tourData___date, order: ASC }
        ) {
          nodes {
            title
            language {
              code
            }
            tourData {
              date
              dateend
              soloist {
                text
                title
              }
              repertoire {
                title
                text
              }
              locations {
                title
                text
              }
              conductor {
                title
                text
              }
            }
          }
        }
      }
    `
  )
  const [uniqueYears, setUniqueYears] = useState([""])
  const [year, setYear] = useState("2018")
  const [filteredTours, setFilteredTours] = useState([])

  useEffect(() => {
    const years = allWpTour.nodes
      .map(value => {
        if (value.language.code !== pageContext.language.code) return false
        return value.tourData.date.substring(0, 4)
      })
      .filter(Boolean)
    const uniqueYear = [...new Set(years)].reverse()
    setUniqueYears(uniqueYear)
    setYear(uniqueYear[0])
  }, [allWpTour])

  useEffect(() => {
    setFilteredTours(
      allWpTour.nodes.filter(value => {
        if (value.language.code !== pageContext.language.code)
          return false
        return value.tourData.date.substring(0, 4) === year
      })
    )
  }, [year, allWpTour])


  const [display, setDisplay] = useState(3)

  // Reset
  useEffect(() => {
    setDisplay(3)
  }, [year])

  return (
    <section className={`tours`}>
      <div className="container-fluid filter">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <h2>{item.title}</h2>
            <select
              className="form-select btn"
              aria-label={strings[language.slug].selectYear}
              value={year}
              onChange={event => {
                setYear(event.target.value)
              }}
            >
              <option value="">{strings[language.slug].selectYear}</option>
              {!!uniqueYears &&
                uniqueYears.map((value, index) => (
                  <option value={value} key={index}>
                    {value}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>
      <div className="container-fluid tours-wrap">
        <h3>{year}</h3>
        {filteredTours.map((item, index) => {
          const startFormat =
            item?.tourData?.date?.substring(4, 6) !==
            item?.tourData?.dateend?.substring(4, 6)
              ? "D MMMM"
              : "D"
          return (
            <div
              className="row item justify-content-center"
              key={index}
              style={{ display: display < index + 1 ? "none" : "flex" }}
            >
              <div className="col-12 text-center">
                {!item.tourData.dateend && (
                  <h3>
                    <Moment
                      format="D MMMM"
                      locale={language.slug}
                      date={item.tourData.date}
                    />
                  </h3>
                )}
                {!!item.tourData.dateend && (
                  <h3>
                    <Moment
                      format={startFormat}
                      locale={language.slug}
                      date={item.tourData.date}
                    />{" "}
                    -{" "}
                    <Moment
                      format="D MMMM"
                      locale={language.slug}
                      date={item.tourData.dateend}
                    />
                  </h3>
                )}
              </div>
              <div className="col-12 col-sm-6 col-md-4 taxonomy">
                {!!item.tourData.conductor.text && (
                  <>
                    {!!item.tourData.conductor.title && (
                      <h4>{item.tourData.conductor.title}</h4>
                    )}
                    {parse(item.tourData.conductor.text)}
                  </>
                )}

                {!!item.tourData.soloist.text && (
                  <>
                    {!!item.tourData.soloist.title && (
                      <h4>{item.tourData.soloist.title}</h4>
                    )}
                    {parse(item.tourData.soloist.text)}
                  </>
                )}
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                {!!item.tourData.repertoire.text && (
                  <>
                    {!!item.tourData.repertoire.title && (
                      <h4>{item.tourData.repertoire.title}</h4>
                    )}
                    {parse(item.tourData.repertoire.text)}
                  </>
                )}
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                {!!item.tourData.locations.text && (
                  <>
                    {!!item.tourData.locations.title && (
                      <h4>{item.tourData.locations.title}</h4>
                    )}
                    {parse(item.tourData.locations.text)}
                  </>
                )}
              </div>
            </div>
          )
        })}
      </div>
      {display < filteredTours.length && (
        <div className="container-fluid show-more text-center">
          <button
            onClick={() => {
              setDisplay(display + 3)
            }}
          >
            {strings[language.slug].showMore}
          </button>
        </div>
      )}
    </section>
  )
}

export default Component
