import React from "react"
import parse from "html-react-parser"
import { Link } from "gatsby"

const Component = ({ item, num, pageContext }) => {
  return (
    <section className={`menu`}>
        <ul className="nav justify-content-center">
          {item?.links?.map((item, i, arr) => (
            <li className="nav-item" key={i}>
              <Link className="nav-link" to={item.link.url} target={item.link.target}>
                {parse(item.link.title)}
              </Link>
            </li>
          ))}
        </ul>
    </section>
  )
}

export default Component
