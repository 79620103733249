import React from "react"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Parallax from '../parallax'
const Component = ({ item, children, small, num }) => {
  const { mask } = item
  const eventImage = {
    image: getImage(item?.image?.localFile),
    alt: item?.image?.altText || ``,
  }

  return (
    <div
      className={`mask-image mask-${mask.position ?? 'center'} mask-number-${
        mask.number ?? '1'
      } ${!!small ? 'mask-small' : ''} ${
        mask.position == 'none' ? 'mask-height-' + mask.height : ''
      }`}
    >
      {!!eventImage.image && (
        <>
          {num < 1 && (
            <Parallax>
              <GatsbyImage
                className="eventImage"
                image={eventImage.image}
                alt={eventImage.alt}
                loading={num < 2 ? 'eager' : 'lazy'}
                itemProp="image"
                imgStyle={{
                  objectFit: 'cover',
                  objectPosition: [
                    item?.imageSettings?.alignX ?? '50%',
                    item?.imageSettings?.alignY ?? '50%',
                  ],
                }}
              />
            </Parallax>
          )}
          {num > 0 && (
            <GatsbyImage
              className="eventImage"
              image={eventImage.image}
              alt={eventImage.alt}
              loading={num < 2 ? 'eager' : 'lazy'}
              itemProp="image"
              imgStyle={{
                objectFit: 'cover',
                objectPosition: [
                  item?.imageSettings?.alignX ?? '50%',
                  item?.imageSettings?.alignY ?? '50%',
                ],
              }}
            />
          )}
        </>
      )}
    </div>
  )
}

Component.defaultProps = {
  small: false,
}

export default Component
