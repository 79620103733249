import React from "react"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import parse from "html-react-parser"

const Component = ({ item, num, pageContext }) => {
  return (
    <section className={`subscription`}>
      <div className="container-fluid">
        <div className="row justify-content-center">
          {!!item.title && (
            <div className="col-12 text-center title">
              <h2>{item.title}</h2>
            </div>
          )}

          <div className="col-12">
            {!!item?.subscriptions &&
              item?.subscriptions.map((item, i, arr) => (
                <div className="row item" key={`sub`+i}>
                  <div className="col-12 image">
                    {item?.image?.localFile && (
                      <GatsbyImage
                        className="subscriptionImage"
                        image={getImage(item.image.localFile)}
                        alt={item?.image?.localFile?.altText ?? ''}
                        itemProp="image"
                      />
                    )}
                  </div>
                  <div className="col-12 col-sm text">
                    <div className="row">
                      <div className="col-12 col-md-8">
                        <div className="text-wrap">
                          {!!item?.title && <h3>{item.title}</h3>}
                          {!!item?.text && parse(item.text)}
                        </div>
                        <div className="link-wrap d-none d-md-block">
                          {!!item?.link && (
                            <a
                              className="btn btn-small"
                              href={item.link.url}
                              target={item.link.target}
                            >
                              {item.link.title}
                            </a>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-4 prices justify-content-md-center">
                        <div className="price-wrap">
                          {!!item?.prices?.price && (
                            <h4>{item.prices.price}</h4>
                          )}
                          {!!item?.prices?.small && (
                            <span>{parse(item.prices.small)}</span>
                          )}
                        </div>
                      </div>

                      <div className="col-12 d-md-none">
                        <div className="link-wrap">
                          {!!item?.link && (
                            <a
                              className="btn btn-small"
                              href={item.link.url}
                              target={item.link.target}
                            >
                              {item.link.title}
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Component
