import React from "react"
import { graphql } from "gatsby"
import Block from "../components/block"
import Layout from "../components/layout"
import Seo from "../components/seo"

const PageTemplate = ({ data: { page, events }, pageContext }) => {
  return (
    <Layout
      template={pageContext.template}
      language={pageContext.language}
      page={{ slug: page.slug, uri: page.uri }}
    >
      <Seo title={page.title} template={pageContext.template} seo={page.seo} />
      <Block
        content={page.pageData?.flexContent || []}
        title={page.title}
        id={page.databaseId}
        events={events.edges}
        pageContext={pageContext}
        key="content"
      />
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query page($id: String!, $buildDate: Float!) {
    events: allWpEvent(
      sort: { fields: eventData___dateStart, order: ASC }
      filter: { eventData: { dateEnd: { gte: $buildDate } } }
    ) {
      edges {
        event: node {
          id
          title
          uri
          databaseId
          eventData {
            description
            subTitle
            dateStart
            dateEnd
            dateFull
            info {
              date
              year: date(formatString: "YYYY", locale: "sv")
              month: date(formatString: "MMMM", locale: "sv")
              day: date(formatString: "dddd", locale: "sv")
              dayNum: date(formatString: "D", locale: "sv")
              extraInfo
              time
              dateEnd
              timeEnd
            }
            dates {
              infoDate
              infoTime
              infoExtraInfo
              ticketDate
              ticketLink
              ticketPrice {
                from
                to
              }
            }
            location {
              name
              locationData {
                address {
                  postCode
                  state
                  streetAddress
                }
              }
            }
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    quality: 85
                    placeholder: BLURRED
                  )
                }
              }
            }
            mask {
              position
              number
            }
            ticket {
              link
              date
              price {
                from
                to
              }
            }
            subscription {
              termTaxonomyId
            }
            location {
              termTaxonomyId
            }
            categories {
              termTaxonomyId
            }
          }
        }
      }
    }
    page: wpPage(id: { eq: $id }) {
      id
      databaseId
      title
      uri
      slug
      seo {
        canonical
        metaDesc
        opengraphDescription
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          localFile {
            publicURL
          }
        }
        schema {
          pageType
          raw
        }
      }
      pageData {
        __typename
        flexContent {
          ... on WpPage_Pagedata_FlexContent_Top {
            fieldGroupName
            slides {
              position
              subTitle
              title
              text
              date(formatString: "dddd D MMMM YYYY", locale: "sv")
              time
              link {
                url
                target
              }
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      quality: 85
                      placeholder: BLURRED
                      blurredOptions: { width: 120 }
                    )
                  }
                }
              }
              imageMobile {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      quality: 85
                      placeholder: BLURRED
                      blurredOptions: { width: 60 }
                      breakpoints: [500, 600, 700]
                    )
                  }
                }
              }
            }
          }
          ... on WpPage_Pagedata_FlexContent_Menu {
            fieldGroupName
            links {
              link {
                title
                url
                target
              }
            }
          }
          ... on WpPage_Pagedata_FlexContent_Events {
            fieldGroupName
            title
            options
            category {
              name
              id
              databaseId
              events {
                nodes {
                  databaseId
                }
              }
            }
            locations {
              name
              id
              databaseId
              events {
                nodes {
                  databaseId
                }
              }
            }
            subscription {
              name
              id
              databaseId
              events {
                nodes {
                  databaseId
                }
              }
            }
          }

          ... on WpPage_Pagedata_FlexContent_Content {
            fieldGroupName
            align
            textAlign
            columns
            columnsText {
              text
            }
            columnsText2 {
              text
            }
            subTitle
            title
            text
          }
          ... on WpPage_Pagedata_FlexContent_Quote {
            fieldGroupName
            quote
            text
          }
          ... on WpPage_Pagedata_FlexContent_Press {
            fieldGroupName
            title
          }
          ... on WpPage_Pagedata_FlexContent_Subscriptions {
            fieldGroupName
            title
            subscriptions {
              title
              text
              link {
                target
                title
                url
              }
              prices {
                price
                small
              }
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 800)
                  }
                }
              }
            }
          }
          ... on WpPage_Pagedata_FlexContent_Image {
            fieldGroupName
            mask {
              position
              number
              height
            }
            imageSettings {
              alignY
              alignX
            }
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    quality: 85
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          ... on WpPage_Pagedata_FlexContent_Tours {
            fieldGroupName
            title
          }
          ... on WpPage_Pagedata_FlexContent_Albums {
            fieldGroupName
            title
          }
          ... on WpPage_Pagedata_FlexContent_Personal {
            fieldGroupName
            title
            personal {
              ... on WpStaff {
                id
                featuredImage {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 1200)
                      }
                    }
                  }
                }
                title
                personalData {
                  text
                  title
                }
              }
            }
          }
          ... on WpPage_Pagedata_FlexContent_Newsletter {
            fieldGroupName
            title
            text
            email
            position
          }
          ... on WpPage_Pagedata_FlexContent_Form {
            fieldGroupName
            title
            text
            email
            position
          }
          ... on WpPage_Pagedata_FlexContent_Customform {
            fieldGroupName
            title
            text
            email
            position
          }
          ... on WpPage_Pagedata_FlexContent_Logotypes {
            fieldGroupName
            title
            logos {
              image {
                id
                altText
                localFile {
                  publicURL
                  ext
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      quality: 85
                      placeholder: BLURRED
                      width: 400
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
