import React, { useState, useEffect } from 'react'
import EventListItem from './eventListItem'

const Component = ({ loading, filteredEvents, activeDate }) => {
  const perPage = 6
  const [display, setDisplay] = useState(perPage)
  useEffect(() => {
    setDisplay(6)
  }, [filteredEvents])

  return (
    <>
      <div className="event-wrap">
        <div className="container-fluid">
          <div className="row event-list">

            {filteredEvents === false && (
              <Loading hits={1} />
            )}

            {!!loading && !!filteredEvents && (
              <Loading hits={filteredEvents?.length} />
            )}

            {!!filteredEvents &&
              filteredEvents.map((item, i, arr) => (
                <EventListItem
                  item={item}
                  key={item.event.id}
                  display={!loading && i < display ? true : false}
                  activeDate={activeDate}
                />
              ))}

            {!loading && filteredEvents?.length == 0 && (
              <div className="container-fluid no-results text-center py-3">
                <h3>Just nu finns inga evenemang</h3>
              </div>
            )}
          </div>
        </div>
      </div>
      {!!filteredEvents && display < filteredEvents?.length && (
        <div className="container-fluid show-more text-center">
          <button
            onClick={() => {
              setDisplay(display + (perPage + 4))
            }}
          >
            Visa fler
          </button>
        </div>
      )}
    </>
  )
}

const Loading = ({ hits }) => {
  const getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max))
  const masks = ['left', 'center', 'right']
  return (
    <>
      {[...Array(Math.min(6, Math.max(hits, 1)))].map((e, i) => (
        <div
          className="col-12 event event-row event-placeholder"
          key={`ph` + i}
        >
          <div className="row">
            <div className="col-12 col-md-6">
              <div
                className={`mask-image mask-right mask-number-${
                  getRandomInt(3) + 1
                } mask-small mask-${masks[getRandomInt(3)]}`}
              >
                <div className="gatsby-image-wrapper eventImage">
                  <div className="ph-wrap">
                    <div className="ph-item p-0 m-0">
                      <div className="ph-col-12">
                        <div className="ph-picture h-100"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 eventData">
              <div className="row h-100">
                <div className="col-12 align-self-start">
                  <div className="ph-item p-0 m-0">
                    <div className="ph-col-12 p-0">
                      <div className="ph-row">
                        <div className="ph-col-4 big"></div>
                        <div className="ph-col-8 empty big"></div>
                        <div className="ph-col-8 xl ml-2"></div>
                        <div className="ph-col-6 big"></div>
                        <div className="ph-col-12 empty"></div>
                        <div className="ph-col-12"></div>
                        <div className="ph-col-12"></div>
                        <div className="ph-col-12"></div>
                        <div className="ph-col-8"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 align-self-end">
                  <div className="row links">
                    <div className="col-6">
                      <div className="ph-item p-0 m-0">
                        <div className="ph-col-12 p-0">
                          <div className="ph-row">
                            <div className="ph-col-10 xl"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default React.memo(Component)
