import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import parse from "html-react-parser"
import Moment from "react-moment"
import "moment/locale/sv"


const Album = item => {
  const { language } = item.pageContext
  const strings = {
    en: {
      price: "Price",
    },
    sv: {
      price: "Pris",
    },
  }
  const image = getImage(item?.featuredImage?.node?.localFile)
return (
  <div className="row item" key={item.id} style={item.style}>
    <div className="col-4 col-sm-3 col-md-3 cover d-none d-sm-block">
      {!!image && (
        <GatsbyImage
          className="eventImage"
          image={image}
          alt={item?.featuredImage?.node?.localFile?.altText ?? ''}
          itemProp="image"
        />
      )}
    </div>
    <div className="col-12 col-sm-4 col-md-5">
      {item?.albumData?.month && (
        <h4 className="date">
          {item.albumData.month} <strong>{item.albumData.year}</strong>
        </h4>
      )}
      {item?.title && <h2>{item.title}</h2>}

      <div className="d-sm-none cover mt-3">
        {!!image && (
          <GatsbyImage
            className="eventImage"
            image={image}
            alt={item?.featuredImage?.node?.localFile?.altText ?? ''}
            itemProp="image"
          />
        )}
      </div>

      {item?.albumData.text && parse(item.albumData.text)}
      {item?.albumData?.price && (
        <h4 className="price">
          {strings[language.slug].price}{' '}
          <strong>{item?.albumData?.price} kr</strong>
        </h4>
      )}

      {!!item?.conductors?.nodes.length && (
        <div className="moreInfo">
          <h4 className="itemTitle">
            {item.conductors.nodes.length > 1 ? 'Dirigenter' : 'Dirigent'}
          </h4>
          {item.conductors.nodes.map((item, index) => (
            <div key={`conductors${item.id}`}>{item.name}</div>
          ))}
        </div>
      )}

      {!!item?.artists?.nodes.length > 0 && (
        <div className="moreInfo">
          <h4 className="itemTitle">
            {item.artists.nodes.length > 1 ? 'Artister' : 'Artist'}
          </h4>
          {item.artists.nodes.map((item, index) => (
            <div key={`artists${item.id}`}>{item.name}</div>
          ))}
        </div>
      )}

      {!!item?.soloists?.nodes.length > 0 && (
        <div className="moreInfo">
          <h4 className="itemTitle">
            {item.soloists.nodes.length > 1 ? 'Soloister' : 'Soloist'}
          </h4>
          {item.soloists.nodes.map((item, index) => (
            <div key={`soloist${item.id}`}>{item.name}</div>
          ))}
        </div>
      )}
    </div>
    <div className="col-12 col-sm-5 col-md-4">
      {item?.albumData.moreInfo.map((item, index) => (
        <div className="moreInfo" key={`moreInfo${index}`}>
          {item?.title && <h4 className="itemTitle">{item.title}</h4>}
          {item?.text && parse(item.text)}
        </div>
      ))}

      {!!item?.composers?.nodes.length > 0 && (
        <div className="moreInfo">
          <h4 className="itemTitle">
            {item.composers.nodes.length > 1 ? 'Kompositörer' : 'Kompositör'}
          </h4>
          {item.composers.nodes.map((item, index) => (
            <div key={`composers${item.id}`}>{item.name}</div>
          ))}
        </div>
      )}
    </div>
  </div>
)}

const Component = ({ item, num, pageContext }) => {

  const { language } = pageContext
  const strings = {
    en: {
      showMore: "Show more",
      noResults: 'No results',
      allComposers: "All composers",
      selectComposer: "Select composer",
      allSoloists: "All soloists",
      selectSoloist: "Select soloist",
      allConductors: "All conductor",
      selectConductor: "Select conductors",
    },
    sv: {
      showMore: "Visa fler",
      noResults: 'Inga resultat',
      allComposers: "Alla kompositörer",
      selectComposer: "Välj kompositör",
      allSoloists: "Alla solister",
      selectSoloist: "Välj solist",
      allConductors: "Alla dirigenter",
      selectConductor: "Välj dirigent",
    },
  }
  const {
    allWpAlbum: { albums },
    allWpSoloist: { soloists },
    allWpArtist: { artists },
    allWpConductor: { conductors },
    allWpComposer: { composers },
  } = useStaticQuery(
    graphql`
      query {
        allWpAlbum(
          sort: { fields: albumData___date, order: DESC }
          filter: { language: { code: { eq: SV } } }
        ) {
          albums: nodes {
            id
            title
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 800)
                  }
                }
              }
            }
            soloists {
              nodes {
                id
                name
                termTaxonomyId
              }
            }
            composers {
              nodes {
                id
                name
                termTaxonomyId
              }
            }
            conductors {
              nodes {
                id
                name
                termTaxonomyId
              }
            }
            artists {
              nodes {
                id
                name
                termTaxonomyId
              }
            }
            albumData {
              price
              moreInfo {
                text
                title
              }
              spotify
              text
              link
              month: date(locale: "SV", formatString: "MMMM")
              year: date(locale: "SV", formatString: "Y")
            }
          }
        }
        allWpArtist(sort: { order: ASC, fields: name }) {
          artists: nodes {
            name
            termTaxonomyId
          }
        }
        allWpSoloist(sort: { order: ASC, fields: name }) {
          soloists: nodes {
            name
            termTaxonomyId
          }
        }
        allWpConductor(sort: { order: ASC, fields: name }) {
          conductors: nodes {
            termTaxonomyId
            name
          }
        }
        allWpComposer(sort: { order: ASC, fields: name }) {
          composers: nodes {
            name
            termTaxonomyId
          }
        }
      }
    `
  )

  const [filter, setFilter] = useState({
    soloist: "",
    conductor: "",
    composer: "",
  })
  const [filteredAlbums, setFilteredAlbums] = useState([])

  // Filter albums
  useEffect(() => {
    setFilteredAlbums(
      albums.filter((album, index) => {
        // Show all
        if (!filter.soloist && !filter.conductor && !filter.composer) {
          return true
        }

        // Filter out soloists who dont match, ignore empty
        if (
          !!filter.soloist &&
          !album?.soloists?.nodes.some(e => e.termTaxonomyId == filter.soloist)
        ) {
          return false
        }
        // Filter out conductors who dont match, ignore empty
        if (
          !!filter.conductor &&
          !album?.conductors?.nodes.some(
            e => e.termTaxonomyId == filter.conductor
          )
        ) {
          return false
        }
        // Filter out composers who dont match, ignore empty
        if (
          !!filter.composer &&
          !album?.composers?.nodes.some(
            e => e.termTaxonomyId == filter.composer
          )
        ) {
          return false
        }

        return true
      })
    )
  }, [filter, albums])


  const [display, setDisplay] = useState(3)

  // Reset 
  useEffect(() => {
    setDisplay(3)
  }, [filter])

  return (
    <section className={`albums`}>
      <div className="container-fluid filter">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <h2>{item.title}</h2>
            <div className="select-wrap">
              <select
                className="form-select btn"
                aria-label={strings[language.slug].selectSoloist}
                value={filter.soloist}
                onChange={event => {
                  setFilter({ ...filter, soloist: event.target.value })
                }}
              >
                <option value="">{strings[language.slug].allSoloists}</option>
                {!!soloists &&
                  soloists.map((item, i) => (
                    <option value={item.termTaxonomyId} key={i}>
                      {item.name}
                    </option>
                  ))}
              </select>
              <select
                className="form-select btn"
                aria-label={strings[language.slug].selectConductor}
                value={filter.conductor}
                onChange={event => {
                  setFilter({ ...filter, conductor: event.target.value })
                }}
              >
                <option value="">{strings[language.slug].allConductors}</option>
                {!!conductors &&
                  conductors.map((item, i) => (
                    <option value={item.termTaxonomyId} key={i}>
                      {item.name}
                    </option>
                  ))}
              </select>
              <select
                className="form-select btn"
                aria-label={strings[language.slug].selectComposer}
                value={filter.composer}
                onChange={event => {
                  setFilter({ ...filter, composer: event.target.value })
                }}
              >
                <option value="">{strings[language.slug].allComposers}</option>
                {!!composers &&
                  composers.map((item, i) => (
                    <option value={item.termTaxonomyId} key={i}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid albums-wrap">
        {filteredAlbums.map((item, index) => (
          <Album
            {...item}
            key={item.id}
            style={{ display: display < index + 1 ? "none" : "flex" }}
            pageContext={pageContext}
          />
        ))}
      </div>

      {filteredAlbums.length == 0 && (
        <div className="container-fluid no-results text-center py-3">
          <h3>{strings[language.slug].noResults}</h3>
        </div>
      )}

      {display < filteredAlbums.length && (
        <div className="container-fluid show-more text-center">
          <button
            onClick={() => {
              setDisplay(display + 3)
            }}
          >
            {strings[language.slug].showMore}
          </button>
        </div>
      )}
    </section>
  )
}

export default Component
