import React from "react"
import parse from "html-react-parser"
import Form from "../form/newsletterForm"

const Component = ({ item, num, pageContext, id, arr }) => {
  const isPreviousForm =
    [
      'Page_Pagedata_FlexContent_Newsletter',
      'Page_Pagedata_FlexContent_Form',
      'Page_Pagedata_FlexContent_Customform',
    ].indexOf(arr[Math.max(0, num - 1)]['fieldGroupName']) != -1
  const isNextForm =
    arr.length !== num + 1 &&
    [
      'Page_Pagedata_FlexContent_Newsletter',
      'Page_Pagedata_FlexContent_Form',
      'Page_Pagedata_FlexContent_Customform',
    ].indexOf(arr[Math.min(arr.length, num + 1)]['fieldGroupName']) != -1
  return (
    <section
      className={`form newsletter ${isPreviousForm ? "mt-sm-0" : ""} ${
        isNextForm ? "mb-sm-0" : ""
      }`}
      key={id + num}
    >
      <div className="container-fluid">
        <div
          className={`row justify-content-center form-pos-${item?.position}`}
        >
          <div className={`col-12 col-sm-6 align-self-center text`}>
            {item.title && <h2 className="mb-4">{item.title}</h2>}
            {item.text && parse(item.text)}
          </div>
          <div className={`col-12 col-sm-6 input`}>
            <div className="bg"></div>
            <Form
              target={(process.env.WPAPI_URL ||
                'https://admin.orebrokonserthus.com/wp-json/') + "newsletter/v1/post/"}
              id={id}
              row={num}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Component
