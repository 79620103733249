import React, { useState, useEffect, useContext } from 'react'
import EventSwiper from "./eventSwiper"
import SearchInput from '../mini/searchInput'
import CategoryFilter from './eventCategoryFilter'
import Accordion from 'react-bootstrap/Accordion'
import AccordionContext from 'react-bootstrap/AccordionContext'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import moment from 'moment'
import 'moment/locale/sv'

const ContextAwareToggle = ({ children, eventKey, callback, className }) => {
  const currentEventKey = useContext(AccordionContext)
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = currentEventKey === eventKey
  return (
    <button
      type="button"
      className={`${className} ${isCurrentEventKey ? '' : 'collapsed'}`}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  )
}

const Component = ({
  id,
  title,
  changeActiveDate,
  changeTaxonomy,
  initialTaxonomies,
  onSearchClick,
}) => {
  const startDate = new Date()
  const [startMonth, setStartMonth] = useState(startDate)
  const dateArray = [...Array(10)].map((v, i) => {
    const month = startDate.getUTCMonth() + i
    const date = i == 0 ? startDate.getUTCDate() : 1
    return new Date(startDate.getUTCFullYear(), month, date, 12).toISOString()
  })

  return (
    <div className="filter-wrap">
      <div className="container-fluid">
        <div className="row">
          {!!title && (
            <div className="col-12 text-center">
              <h2 className="subtitle">{title}</h2>
            </div>
          )}
          <div className="col-12 dates-wrap">
            <EventSwiper
              startMonth={startMonth}
              changeActiveDate={(date) => changeActiveDate(date)}
            />
          </div>
        </div>
      </div>
      <div className="filters">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10">
              <Accordion className="row" defaultActiveKey="0">
                <div className="col-12 col-md-4 order-1">
                  <ContextAwareToggle
                    variant="link"
                    className="dropdown btn accordion-button"
                    eventKey={'toggle0'}
                  >
                    Filtrera evenemang
                  </ContextAwareToggle>
                </div>
                <CategoryFilter
                  changeTaxonomy={(tax) => changeTaxonomy(tax)}
                  initialTaxonomies={initialTaxonomies}
                />
                <div className="col-12 col-md-4 order-3">
                  <div className="input-group">
                    <select
                      className="form-select from-select-month btn"
                      aria-label="Välj månad"
                      value={startMonth}
                      onChange={(e) => setStartMonth(e.target.value)}
                    >
                      {dateArray.map((date, v) => (
                        <option value={date} key={date}>
                          {moment(date).locale('sv').format('MMMM')}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-12 col-md-4 order-4">
                  <SearchInput
                    onSearchClick={(searchQuery) => {
                      onSearchClick(searchQuery)
                    }}
                  />
                </div>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Component)