import React, { useState, useRef } from 'react'
import Input from './Input'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { trackCustomEvent } from 'gatsby-plugin-google-gtag'

const Component = ({ target, id, row }) => {
  const form = useRef(null)
  const [formData, setFormData] = useState({})
  const [formClass, setFormClass] = useState('needs-validation')
  const { executeRecaptcha } = useGoogleReCaptcha()

  const reduceInputValues = (inputElements) => {
    const arrElements = Array.prototype.slice.call(inputElements)
    const formValues = arrElements
      .filter((elem) => elem.name.length > 0)
      .map((x) => {
        const { typeMismatch } = x.validity
        const { name, type, value } = x
        return {
          name,
          type,
          typeMismatch, //we use typeMismatch when format is incorrect(e.g. incorrect email)
          value,
          valid: x.checkValidity(),
        }
      })
      .reduce((acc, currVal) => {
        //then we finally use reduce, ready to put it in our state
        const { value, valid, typeMismatch } = currVal
        acc[currVal.name] = {
          value,
          valid,
          typeMismatch,
        }
        return acc
      }, {})
    return formValues
  }

  const checkAllFieldsValid = (inputValues) => {
    return !Object.keys(inputValues)
      .map((x) => inputValues[x])
      .some((field) => !field.valid)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!executeRecaptcha) {
      return
    }
    const tokenCaptcha = await executeRecaptcha('newsletter')

    trackCustomEvent({
      category: 'Button',
      action: 'Click',
      label: 'CustomForm',
    })

    const submitButton = form.current.querySelector('button.submit')
    const inputValues = reduceInputValues(form.current.elements)
    const allFieldsValid = checkAllFieldsValid(inputValues)
    if (allFieldsValid) {
      submitButton.setAttribute('disabled', '')
      submitButton.innerHTML =
        'Skickar <span class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>'

      fetch(target, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          id: id,
          row: row,
          debug: false,
          token: tokenCaptcha,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log('Response: ', response, response.data.status)
          if (response.data.status === 200) {
            submitButton.innerHTML = 'Skickat!'
            submitButton.removeAttribute('disabled')
            alert(response.message)
          } else {
            console.log('Request failed', response)
            submitButton.removeAttribute('disabled')
            submitButton.innerHTML = 'Försök igen!'
          }
        })
        .catch(function (error) {
          console.log('Request failed', error)
          submitButton.removeAttribute('disabled')
          submitButton.innerHTML = 'Försök igen!'
        })
    } else {
      submitButton.innerHTML = 'Fyll i allt först!'
    }
  }

  const handleSubmitClick = () => {
    setFormClass('needs-validation was-validated')
  }

  const handleInputChange = (e) => {
    const value = e.value
    const name = e.name
    setFormData({ ...formData, [name]: value })
  }
  return (
    <form
      className={formClass}
      action={target}
      method="post"
      onSubmit={handleSubmit}
      ref={form}
      noValidate
    >
      <div className="row">
        <div className="col-12 mb-3">
          <Input
            type="text"
            name="name"
            title="Namn"
            error="Fyll i ditt namn."
            autocomplete="name"
            required={true}
            value={formData?.name ?? ''}
            onValueChange={(e) => handleInputChange(e)}
          />
        </div>
        <div className="col-12 mb-3">
          <Input
            type="textarea"
            name="adress"
            title="Adress"
            autocomplete="street-address"
            error="Fyll i din adress."
            required={true}
            value={formData?.adress ?? ''}
            onValueChange={(e) => handleInputChange(e)}
          />
        </div>
        <div className="col-12 mb-3">
          <Input
            type="text"
            name="cellphone"
            autocomplete="tel"
            title="Mobilnummer"
            error="Fyll i ditt mobilnummer."
            required={true}
            value={formData?.cellphone ?? ''}
            onValueChange={(e) => handleInputChange(e)}
          />
        </div>
        <div className="col-12 mb-3">
          <Input
            type="email"
            name="email"
            title="E-postadress"
            error="Fyll i din e-post."
            autocomplete="email"
            required={true}
            value={formData?.email ?? ''}
            onValueChange={(e) => handleInputChange(e)}
          />
        </div>
        <div className="col-12 mb-3">
          <Input
            type="text"
            name="birth"
            title="Födelsenummer (sex första)"
            error="Fyll i ditt födelsenummer."
            required={true}
            value={formData?.birth ?? ''}
            onValueChange={(e) => handleInputChange(e)}
          />
        </div>
        <div className="col-12 mb-3 mt-3">
          <Input
            type="checkbox"
            name="instrument"
            title="Instrument"
            error="Fyll i ditt instrument här."
            required={true}
            value={formData?.instrument ?? ''}
            onValueChange={(e) => handleInputChange(e)}
          />
        </div>
        <div className="col-12 mb-3">
          <Input
            type="text"
            name="yearsplayed"
            title="Antal år du spelat"
            error="Fyll i hur många år du spelat."
            required={true}
            value={formData?.yearsplayed ?? ''}
            onValueChange={(e) => handleInputChange(e)}
          />
        </div>
        <div className="col-12 mb-3">
          <Input
            type="text"
            name="gymprogram"
            title="Gymnasium / program"
            error="Fyll i vilket gymnasium / program."
            required={true}
            value={formData?.gymprogram ?? ''}
            onValueChange={(e) => handleInputChange(e)}
          />
        </div>
        <div className="col-12 mb-3">
          <Input
            type="text"
            name="ref"
            title="Namn på referensperson"
            error="Fyll i namn på referenspersonen."
            required={true}
            value={formData?.ref ?? ''}
            onValueChange={(e) => handleInputChange(e)}
          />
        </div>
        <div className="col-12 mb-3">
          <Input
            type="textarea"
            name="other"
            title="Något övrigt du tycker vi bör veta om dig"
            error="Fyll i övrig info."
            required={false}
            value={formData?.other ?? ''}
            onValueChange={(e) => handleInputChange(e)}
          />
        </div>
        <div className="col-12 mb-3 mt-3">
          <Input
            type="textarea"
            name="experience"
            title="Har du erfarenhet av komposition, låtskrivande eller arrangering?"
            error="Fyll i om du har erfarenhet av nåt av dessa."
            required={false}
            value={formData?.experience ?? ''}
            onValueChange={(e) => handleInputChange(e)}
          />
        </div>
        <div className="col-12 mb-3">
          <Input
            type="textarea"
            name="links"
            title="Länkar till kompositioner"
            error="Fyll i länkar till dina kompositoner."
            required={false}
            value={formData?.links ?? ''}
            onValueChange={(e) => handleInputChange(e)}
          />
        </div>
      </div>

      <div className="form-check mb-3">
        <input
          type="checkbox"
          className="form-check-input"
          aria-labelledby="inputAgreement"
          name="agreement"
          id={`inputAgreementCheckbox${row}`}
          onChange={() => {
            setFormData({
              ...formData,
              agreement: !(formData?.agreement ?? false),
            })
          }}
          checked={formData?.agreement ?? false}
          required
        />
        <label
          className="form-check-label"
          id={`inputAgreement${row}`}
          htmlFor={`inputAgreementCheckbox${row}`}
        >
          Jag godkänner att mina uppgifter hanteras av Örebro Konserthus.
        </label>

        <div className="invalid-feedback">
          Du måste godkänna villkoren innan du skickar in.
        </div>
      </div>

      <button type="submit" className="submit btn" onClick={handleSubmitClick}>
        Skicka in
      </button>
      <div className="row small-text mt-3">
        <div className="col-12">
          <p>
            reCAPTCHA from Google{' '}
            <a
              href="https://policies.google.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              privacy policy
            </a>{' '}
            and{' '}
            <a
              href="https://policies.google.com/terms"
              target="_blank"
              rel="noreferrer"
            >
              user terms
            </a>
            .
          </p>
        </div>
      </div>
    </form>
  )
}
export default Component
