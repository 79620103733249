import React from "react"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from "gatsby"
import EventDate from "./eventDate"
import getActiveDateData from '../mini/getActiveDateData'
import EventTicketLink from './eventTicketLink'

const Component = ({ item, display, activeDate }) => {
  const { eventData } = item?.event
  const image = getImage(eventData?.image?.localFile?.childImageSharp)
  const alt = eventData?.image?.altText || ``
  const activeDateData = getActiveDateData({ eventData, activeDate })
  return (
    <div
      className="col-12 event event-row"
      style={{ display: !!display ? 'block' : 'none' }}
      itemScope
      itemType="https://schema.org/Event"
    >
      <div className="row">
        <div className="col-12 col-md-6">
          {!!image && (
            <GatsbyImage
              className="roundedEventImage"
              image={image}
              alt={alt}
              itemProp="image"
            />
          )}
        </div>
        {!!eventData.location?.name && (
          <div
            className="d-none"
            itemProp="location"
            itemScope
            itemType="https://schema.org/Place"
          >
            <meta itemProp="name" content={eventData.location?.name} />
            {eventData.location?.locationData?.address && (
              <div
                itemProp="address"
                itemScope
                itemType="https://schema.org/PostalAddress"
              >
                <meta
                  itemProp="streetAddress"
                  content={
                    eventData.location?.locationData?.address.streetAddress
                  }
                />
                <meta
                  itemProp="addressRegion"
                  content={eventData.location?.locationData?.address.state}
                />
                <meta
                  itemProp="postalCode"
                  content={eventData.location?.locationData?.address.postCode}
                />
              </div>
            )}
          </div>
        )}
        <div className="col-12 col-md-6 eventData">
          <div className="row h-100">
            <div className="col-12 align-self-start">
              <EventDate activeDate={activeDate} eventData={eventData} />
              <Link
                to={item.event.uri}
                itemProp="url"
                className="main-link"
                id={item.event.id}
              >
                <h3 itemProp="name">{item.event.title}</h3>
              </Link>
              <h4>{eventData.subTitle}</h4>
              <p className="description">{eventData.description}</p>
              <link itemProp="sameAs" href={item.event.uri} />
            </div>
            <div className="col-12 align-self-end">
              <div className="row links">
                <div className="col-6">
                  <Link
                    to={item.event.uri}
                    className="btn btn-inverted"
                    itemProp="url"
                    aria-describedby={item.event.id}
                  >
                    Läs mer
                  </Link>
                </div>
                <div
                  className="col-6"
                  itemProp="offers"
                  itemScope
                  itemType="https://schema.org/Offer"
                >
                  <EventTicketLink
                    activeDate={activeDate}
                    activeDateData={activeDateData}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Component)
