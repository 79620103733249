import React, { useState } from 'react'
import { graphql, useStaticQuery } from "gatsby"
import Accordion from 'react-bootstrap/Accordion'
import Checkbox from './checkbox'

const Component = ({ changeTaxonomy, initialTaxonomies }) => {
  const { eventcategories, locations, subscriptions } = useStaticQuery(
    graphql`
      query {
        eventcategories: allWpEventcategory(filter: { count: { gte: 1 } }) {
          nodes {
            databaseId
            id
            name
          }
        }
        locations: allWpLocation(filter: { count: { gte: 1 } }) {
          nodes {
            databaseId
            id
            name
          }
        }
        subscriptions: allWpSubscription(filter: { count: { gte: 1 } }) {
          nodes {
            databaseId
            id
            name
          }
        }
      }
    `
  )

  const [obj, setObj] = useState(initialTaxonomies)

  const addOrRemove = (array, value) => {
    const index = array.indexOf(value)
    if (index === -1) {
      array.push(value)
    } else {
      array.splice(index, 1)
    }
    return array
  }
  const handleCheckbox = ({ databaseId, type }) => {
    const object = {
      locations:
        type == 'locations'
          ? addOrRemove(obj.locations, databaseId)
          : obj.locations,
      subscriptions:
        type == 'subscriptions'
          ? addOrRemove(obj.subscriptions, databaseId)
          : obj.subscriptions,
      eventcategories:
        type == 'eventcategories'
          ? addOrRemove(obj.eventcategories, databaseId)
          : obj.eventcategories,
    }
    setObj(object)
    changeTaxonomy(object)
  }

  return (
    <div className="col-12 order-2 order-md-5">
      <Accordion.Collapse className="accordion-collapse" eventKey={'toggle0'}>
        <div className="row">
          {eventcategories &&
            eventcategories.nodes.map((checkbox) => (
              <Checkbox
                key={checkbox.id}
                initialValue={initialTaxonomies.eventcategories.includes(
                  checkbox.databaseId
                )}
                type="eventcategories"
                onChange={handleCheckbox}
                item={checkbox}
              />
            ))}
          {locations &&
            locations.nodes.map((checkbox) => (
              <Checkbox
                key={checkbox.id}
                initialValue={initialTaxonomies.locations.includes(
                  checkbox.databaseId
                )}
                type="locations"
                onChange={handleCheckbox}
                item={checkbox}
              />
            ))}
          {subscriptions &&
            subscriptions.nodes.map((checkbox) => (
              <Checkbox
                key={checkbox.id}
                initialValue={initialTaxonomies.subscriptions.includes(
                  checkbox.databaseId
                )}
                type="subscriptions"
                onChange={handleCheckbox}
                item={checkbox}
              />
            ))}
        </div>
      </Accordion.Collapse>
    </div>
  )
}

export default Component

Component.defaultProps = {
  initialTaxonomies: {
    locations: [],
    subscriptions: [],
    eventcategories: [],
  },
}