import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
const Component = ({ item }) => {
  const { allWpPressrelease } = useStaticQuery(
    graphql`
      query {
        allWpPressrelease(sort: { order: DESC, fields: date }) {
          totalCount
          nodes {
            id
            slug
            title
            uri
            date(locale: "sv", formatString: "D MMMM")
            year: date(locale: "sv", formatString: "YYYY")
          }
        }
      }
    `
  )
  const [display, setDisplay] = useState(12)

  // Reset
  useEffect(() => {
    setDisplay(12)
  }, [allWpPressrelease])

  return (
    <section className={`press`}>
      <div className="container-fluid">
        <div className="row justify-content-center">
          {!!item.title && (
            <div className="col-12 col-md-8 col-lg-6 text-center mb-5">
              <h2>{item.title}</h2>
            </div>
          )}
        </div>
        <div className="row">
          {!!allWpPressrelease.nodes &&
            allWpPressrelease.nodes.map((item, i) => (
              <div
                className="col-12 item"
                key={item.id}
                style={{ display: display < i + 1 ? "none" : "block" }}
              >
                <h4 className="date">
                  <strong>{item.date}</strong> {item.year}
                </h4>
                <Link to={item.uri}>
                  <h2 className="title">{item.title}</h2>
                </Link>
              </div>
            ))}
        </div>
      </div>
      {!!allWpPressrelease?.totalCount &&
        display < allWpPressrelease?.totalCount && (
          <div className="container-fluid show-more text-center">
            <button
              onClick={() => {
                setDisplay(display + 6)
              }}
            >
              Visa fler
            </button>
          </div>
        )}
    </section>
  )
}

export default Component