import React from "react"
import parse from "html-react-parser"

const Component = ({ item, num, pageContext }) => {
  return (
    <section className={`content text-${item.textAlign ?? 'left'}`}>
      <div className="container-fluid">
        <div
          className={`row ${
            item.align == 'center' ? 'justify-content-center' : ''
          }`}
        >
          <div
            className={`${item.columns == 3 ? 'col-12' : ''} ${
              item.columns == 2 ? 'col-12' : ''
            } ${item.columns == 1 ? 'col-12 col-md-10 col-lg-8' : ''}`}
          >
            <div className="row">
              {item?.subTitle && (
                <div className="col-12 subTitle">
                  <h4>{item.subTitle}</h4>
                </div>
              )}
              {item?.title && (
                <div className="col-12 title">
                  <h1>{item.title}</h1>
                </div>
              )}
              {item?.columns == 1 && (
                <div className={`col-12 main-content columns-1`}>
                  {!!item?.text && parse(item.text)}
                </div>
              )}
              {item?.columns == 2 && (
                <div className={`col-12 main-content columns-2`}>
                  <div className="row">
                    {item?.columnsText2?.map((data, i, arr) => (
                      <div className="col-12 col-sm-6" key={`c2${i}`}>
                        {data.text && parse(data.text)}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {item?.columns == 3 && (
                <div className={`col-12 main-content columns-3`}>
                  <div className="row">
                    {item?.columnsText?.map((data, i, arr) => (
                      <div className="col-12 col-sm-6 col-md-4" key={`c3${i}`}>
                        {data.text && parse(data.text)}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Component
