import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
const Component = ({ item, num, pageContext }) => {
  const { title, logos } = item
  return (
    <section className={`logotypes`}>
      <div className="container-fluid">
        {title && <h4>{title}</h4>}
        <div className="row align-items-center">
          {logos?.map((item, i, arr) => {
            if (!item?.image) return
            const image = {
              image: getImage(item?.image?.localFile),
              alt: item?.image?.altText || ``,
            }
            return (
              <div
                className="col-4 col-md-3 col-lg-2 text-center"
                key={i}
              >
                {item?.image?.localFile?.ext !== '.svg' && (
                  <GatsbyImage
                    className="logotype mw-100"
                    image={image.image}
                    alt={image.alt}
                  />
                )}
                {item?.image?.localFile?.ext === '.svg' && (
                  <img
                    className="logotype svg mw-100"
                    src={item?.image?.localFile?.publicURL}
                    alt={image.alt}
                  />
                )}
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default Component
