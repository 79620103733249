import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Moment from 'react-moment'
import 'moment/locale/sv'
import { Navigation, A11y, Virtual } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'


const Component = ({ changeActiveDate, startMonth }) => {
  const getDaysArray = (st) => {
    const start = new Date(st)
    let end = new Date(start)
    end.setMonth(start.getMonth() + 4)
    for (
      var arr = [], dt = new Date(start);
      dt <= end;
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(new Date(dt))
    }
    return arr
  }
  const startDate = new Date()
  const [dates, setDates] = useState(getDaysArray(startDate))

  useEffect(() => {
    const array = getDaysArray(startMonth)
    setDates(array)
    if (swiper) {
      swiper.updateSlides()
      swiper.slideTo(0, 0)
      handleActiveDate(array[0])
    }
  }, [startMonth])

  const {
    eventDates: { allEventDates },
  } = useStaticQuery(
    graphql`
      query {
        eventDates {
          allEventDates: results
        }
      }
    `
  )
  const [swiper, setSwiper] = useState(false)
  //const [activeDate, setActiveDate] = useState(false)
  const [activeDateShort, setActiveDateShort] = useState(false)
  const handleActiveDate = (date) => {
    setActiveDateShort(date?.toLocaleDateString('sv-SE').replace(/\D/g, ''))
    changeActiveDate(date)
  }
  return (
    <Swiper
      modules={[Navigation, A11y, Virtual]}
      spaceBetween={10}
      navigation
      slidesPerView={12}
      slideToClickedSlide={false}
      onSwiper={setSwiper}
      breakpoints={{
        320: {
          slidesPerView: 4,
        },
        375: {
          slidesPerView: 6,
        },
        640: {
          slidesPerView: 8,
        },
        980: {
          slidesPerView: 10,
        },
        1200: {
          slidesPerView: 12,
        },
      }}
      virtual
    >
      <div className="swiper-item-wrap">
        {dates.map((date, i, arr) => {
          const currentDate = date
            ?.toLocaleDateString('sv-SE')
            .replace(/\D/g, '')
          const getDate = date.getDate()
          const dateHasEvents = allEventDates?.includes(currentDate)
          return (
            <SwiperSlide key={date} virtualIndex={i}>
              <button onClick={() => handleActiveDate(date)}>
                {(getDate === 1 || i === 0) && (
                  <h4>
                    <Moment format="MMMM" locale="sv" date={date} />
                  </h4>
                )}
                <div
                  className={`wrap ${dateHasEvents ? 'has-dot' : ''} ${
                    currentDate === activeDateShort ? 'active' : ''
                  }`}
                >
                  <h3>
                    <Moment format="D" locale="sv" date={date} />
                  </h3>
                  <h5>
                    <Moment format="ddd" locale="sv" date={date} />
                  </h5>
                  <div className="dot" />
                </div>
              </button>
            </SwiperSlide>
          )
        })}
      </div>
    </Swiper>
  )
}

export default Component
