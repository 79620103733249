import React from "react"
import parse from "html-react-parser"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
const Component = ({ item, num, pageContext }) => {
  const { personal } = item
  return (
    <section className={`personal`}>
      <div className="container-fluid">
        <div className="row">
          {!!item.title && (
            <div className="col-12">
              <h3>{item.title}</h3>
            </div>
          )}
          {!!personal &&
            personal.map((item, i, arr) => (
              <div
                className="col-12 col-sm-6 col-md-4"
                key={`personal` + i + item.id}
              >
                <GatsbyImage
                  className="personalImage"
                  image={getImage(
                    item?.featuredImage?.node?.localFile?.childImageSharp
                  )}
                  alt={item?.featuredImage?.node?.altText}
                  itemProp="image"
                />
                {!!item?.title && <h2>{item.title}</h2>}
                {!!item?.personalData?.title && (
                  <h4>{item.personalData.title}</h4>
                )}
                {!!item?.personalData?.text && parse(item.personalData.text)}
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}

export default Component
