/* eslint-disable react/no-array-index-key */
import React from "react"
import TopSlider from "./blocks/topSlider"
import Menu from "./blocks/menu"
import Events from "./blocks/events"
import Content from "./blocks/content"
import Quote from "./blocks/quote"
import Image from "./blocks/image"
import Albums from "./blocks/albums"
import Tours from "./blocks/tours"
import Personal from "./blocks/personal"
import Logotypes from './blocks/logotypes'
import Form from "./blocks/form"
import Customform from './blocks/customForm'
import Newsletter from "./blocks/newsletter"
import Subscription from "./blocks/subscription"
import Press from "./blocks/press"

const Component = props => {
  const { id, content } = props

  const layouts = {
    Page_Pagedata_FlexContent_Top: TopSlider,
    Page_Pagedata_FlexContent_Menu: Menu,
    Page_Pagedata_FlexContent_Events: Events,
    Page_Pagedata_FlexContent_Content: Content,
    Page_Pagedata_FlexContent_Quote: Quote,
    Page_Pagedata_FlexContent_Image: Image,
    Page_Pagedata_FlexContent_Albums: Albums,
    Page_Pagedata_FlexContent_Tours: Tours,
    Page_Pagedata_FlexContent_Personal: Personal,
    Page_Pagedata_FlexContent_Form: Form,
    Page_Pagedata_FlexContent_Customform: Customform,
    Page_Pagedata_FlexContent_Newsletter: Newsletter,
    Page_Pagedata_FlexContent_Subscriptions: Subscription,
    Page_Pagedata_FlexContent_Logotypes: Logotypes,
    Page_Pagedata_FlexContent_Press: Press,
    Page_default: Content,
  }

  return (
    <>
      {!!content && content.map((item, i, arr) => {
        const layoutType = item.fieldGroupName
        const ComponentTag = layouts[layoutType]
          ? layouts[layoutType]
          : layouts["page_default"]
        return (
          <ComponentTag
            item={item}
            key={id + i + layoutType}
            id={id}
            arr={arr}
            num={i}
            {...props}
          />
        )
      })}
    </>
  )
}

export default Component
